import React, { useEffect, useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import TextInput from "commons/components/TextInput";
import Modal from "commons/components/Modal";
import { useNavigate } from "react-router-dom";
import Typography from "commons/components/Typography";
import Button from "commons/components/Button";
import Flexbox from "commons/components/Flexbox";
import Divider from "commons/components/Divider";
import { useToast } from "commons/util/useToast";
import { useRecipeListDispatch } from "App/RecipeListContext";
import callApi from "commons/util/callApi";
import { usePreferences } from "App/PreferencesContext";
import { useLocationBase } from "commons/util/useLocationBase";
import { useTranslation } from "react-i18next";
import { getRecipeWithCustomScraper, getRecipeWithSpoonacularScraper } from "./recipeHelpers";

const ModalStyled = styled(Modal)`
  max-width: 100%;
  width: 640px;
  height: auto;
`;

// const InfoBox = styled(Flexbox)`
//   background-color: var(--orange-190);
// `;

function NewWebRecipeModal({ className }) {
  const { t } = useTranslation();
  const [url, setUrl] = useState("");
  const [urlErrorMessage, setUrlErrorMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const locationBase = useLocationBase();
  const { showToast } = useToast();
  const dispatch = useRecipeListDispatch();
  const { preferences } = usePreferences();

  useEffect(() => {
    setUrlErrorMessage("");
  }, [url]);

  async function saveAndOpenRecipe(recipe) {
    try {
      const res = await callApi("recipes", "post", recipe);
      dispatch({ type: "CREATE", recipe: { ...recipe, id: res.id } });
      showToast(t("Recipes.RecipeImported"));
      navigate(`${locationBase}/${res.id}`);
    } catch (e) {
      showToast(t("Common.GenericError"), "error");
    }
  }

  async function extractRecipeFromWebsite() {
    try {
      setIsProcessing(true);
      let newRecipe = await getRecipeWithCustomScraper({ url, preferences });
      if (!newRecipe) {
        newRecipe = await getRecipeWithSpoonacularScraper({ url, preferences });
      }
      saveAndOpenRecipe(newRecipe);
    } catch (e) {
      if (e.response?.data.message === "URL invalid") {
        setUrlErrorMessage(t("Recipes.RecipeUrlInvalid"));
      } else if (e.message === "Access denied") {
        setUrlErrorMessage(t("Recipes.AccessDeniedError"));
      } else {
        showToast(t("Common.GenericError"), "error");
        console.log(e);
      }
      setIsProcessing(false);
    }
  }

  function validateUrlAndExtract() {
    if (!url) {
      setUrlErrorMessage(t("Recipes.RecipeUrlRequired"));
      return false;
    } else {
      extractRecipeFromWebsite();
    }
  }

  return (
    <ModalStyled
      className={className}
      onClose={() => navigate(locationBase)}
      content={closeModal => (
        <>
          <Flexbox flexDirection="column" gap={24} padding={24} paddingBottom={40}>
            <Typography variant="h3">{t("Recipes.ImportRecipeFromWeb")}</Typography>
            <TextInput
              value={url}
              onChange={setUrl}
              label={t("Recipes.RecipeUrl")}
              placeholder="https://"
              errorMessage={urlErrorMessage}
              onConfirm={validateUrlAndExtract}
              autoFocus
              maxLength={800}
            />
            {/* <InfoBox gap={8} padding={8}>
          <Icon name="warning" color="orange-100" size={18} />
          <Typography variant="label" color="orange-100">
            Only import content you have permission to use. You are responsible for any content you import.
          </Typography>
        </InfoBox> */}
          </Flexbox>
          <Divider />
          <Flexbox justifyContent="flex-end" padding="16px 24px" gap={8}>
            <Button onClick={closeModal} variant="tertiary">
              {t("Common.Cancel")}
            </Button>
            <Button onClick={validateUrlAndExtract} loading={isProcessing}>
              {t("Recipes.ImportRecipe")}
            </Button>
          </Flexbox>
        </>
      )}
    />
  );
}

NewWebRecipeModal.propTypes = {
  className: T.string,
};

export default NewWebRecipeModal;
