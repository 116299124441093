import { UNIT_SYSTEMS } from "App/Settings/Preferences/Preferences";
import callApi from "commons/util/callApi";
import { mimeMap } from "commons/util/constants";

async function uploadImageAndGetUrl(originalImageUrl, imageType) {
  const mime = imageType.includes("image/") ? imageType : mimeMap[imageType];
  if (mime) {
    try {
      const { imageUrl } = await callApi(`images?url=${encodeURIComponent(originalImageUrl)}`, "post", null, {
        "content-type": mime,
      });
      return imageUrl;
    } catch {
      return "";
    }
  }
  return "";
}

function getSpoonacularSteps(analyzedInstructions) {
  if (analyzedInstructions.length === 1) {
    return (
      analyzedInstructions[0]?.steps.map(({ number, step }) => ({
        id: number,
        number,
        text: step,
      })) || []
    );
  } else {
    const flattenedSteps = analyzedInstructions.flatMap(({ steps }) => steps.map(step => step));
    return flattenedSteps.map((step, index) => ({
      id: index + 1,
      number: index + 1,
      text: step.step,
    }));
  }
}

export async function getRecipeWithSpoonacularScraper({ url, preferences }) {
  const res = await callApi(`spoonacular/recipes/extract?url=${encodeURIComponent(url)}`, "get");

  const {
    title,
    sourceUrl,
    image,
    imageType,
    servings,
    preparationMinutes,
    readyInMinutes,
    analyzedInstructions,
    extendedIngredients,
  } = res;

  if (res.analyzedInstructions.length === 0) {
    throw new Error("Access denied");
  }

  const imageUrl = await uploadImageAndGetUrl(image, imageType);

  const newRecipe = {
    title,
    source: sourceUrl,
    imageURL: imageUrl,
    servings: servings === -1 ? 1 : servings,
    isFavorite: false,
    neverCooked: true,
    activeTime: preparationMinutes === -1 ? 0 : preparationMinutes,
    totalTime: readyInMinutes === -1 ? 0 : readyInMinutes,
    dateCreated: Date.now(),
    dateLastEdited: Date.now(),
    steps: getSpoonacularSteps(analyzedInstructions),
    ingredients: extendedIngredients.map(({ amount, unit, measures, name, meta }, id) => ({
      id: id + 1, // dnd-kit identifiers must be bigger than 0
      amount:
        preferences.unitSystem === UNIT_SYSTEMS.none
          ? amount
          : preferences.unitSystem === UNIT_SYSTEMS.metric
          ? measures.metric.amount
          : measures.us.amount,
      unit:
        preferences.unitSystem === UNIT_SYSTEMS.none
          ? unit
          : preferences.unitSystem === UNIT_SYSTEMS.metric
          ? measures.metric.unitShort
          : measures.us.unitShort,
      label: name,
      note: meta.filter(el => el !== "()").join(", "),
    })),
    notes: "",
    tagIds: [],
  };

  return newRecipe;
}

export async function getRecipeWithCustomScraper({ url, preferences }) {
  const res = await callApi(`recipes/extract?url=${encodeURIComponent(url)}`, "get");

  if (Object.keys(res).length === 0) {
    return null;
  }

  const {
    title,
    source,
    imageURL,
    servings,
    prepTime,
    totalTime,
    steps,
    ingredients,
    notes,
  } = res;

  // Is this still true?
  if (steps.length === 0) {
    throw new Error("Access denied");
  }

  const imageType = imageURL?.split(".").pop();
  const uploadedImageUrl = await uploadImageAndGetUrl(imageURL, imageType);

  const newRecipe = {
    title,
    source: source,
    imageURL: uploadedImageUrl,
    servings: servings === -1 ? 1 : servings,
    isFavorite: false,
    neverCooked: true,
    activeTime: prepTime === -1 ? 0 : prepTime,
    totalTime: totalTime === -1 ? 0 : totalTime,
    dateCreated: Date.now(),
    dateLastEdited: Date.now(),
    steps: steps.map(step => ({ id: step.id + 1, text: step.text, number: step.id + 1 })), // Remove number property
    ingredients: ingredients.map(({ amount, unit, measures, label }, id) => ({
      id: id + 1, // dnd-kit identifiers must be bigger than 0
      amount:
        preferences.unitSystem === UNIT_SYSTEMS.none
          ? amount
          : preferences.unitSystem === UNIT_SYSTEMS.metric
          ? measures.metric.amount
          : measures.us.amount,
      unit:
        preferences.unitSystem === UNIT_SYSTEMS.none
          ? unit
          : preferences.unitSystem === UNIT_SYSTEMS.metric
          ? measures.metric.unitShort
          : measures.us.unitShort,
      label: label,
      note: "",
    })),
    notes: notes,
    tagIds: [],
  };

  return newRecipe;
}
